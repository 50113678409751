import SpanBold from "../../Components/SpanBold/SpanBold";
import fireApp from "../../firebase/fireApp";

const ComponentUserCard = ({ data }) => {
    return (
        <>
            <div className="card p-4 p-md-5 py-5  bg-light text-center">
                <div className="text-center">
                    <img src={data.photoUrl} alt="" className="userAvatar mb-4" />
                </div>
                <h3>{data.name} </h3>
                <SpanBold title="Email" text={data.email} />
                <SpanBold title="Phone" text={data.phone} />
                <p className={" mb-2"} >uID : <span className="font-weight-bold text-small">{data.userId} </span> </p>
                {/* <SpanBold title="uID" text={data.userId}/> */}
                {/* <SpanBold title="User Type: " text={data.userType}/> */}
            </div>
        </>
    );
};

export default ComponentUserCard;

