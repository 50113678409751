import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router";
import SectionSpinner from "../../Components/Spinner/SectionSpinner";
import { subscriptionsCollectionRef } from "../../firebase/fireApp";
import { useAuth } from "../../hooks/useAuth";
import { usePromo } from "../../hooks/usePromo";

const PageMySales = () => {
    const { adminDetails } = useAuth();
    const history = useHistory()
    const { userPromo, allSubscriptions, setAllSubscriptions, allSubLoading, setAllSubLoading, allSubsExists, setAllSubsExists } = usePromo();
    const [viewDetails, setViewDetails] = useState(false)
    // const [allSubscriptions, setAllSubscriptions] = useState(null);
    // const [allSubLoading, setAllSubLoading] = useState(true);
    // const [allSubsExists, setAllSubsExists] = useState(true);
    useEffect(() => {
        if (userPromo) {
            subscriptionsCollectionRef.where("promoCode", "==", userPromo.id).orderBy("course.startAt", "desc").onSnapshot((querySnapshot) => {
                let fetchedSubs = [];
                querySnapshot.forEach((doc) => {
                    fetchedSubs.push({ id: doc.id, ...doc.data() })
                })
                if (fetchedSubs) {
                    setAllSubscriptions(fetchedSubs);
                    setAllSubLoading(false);
                } else {
                    setAllSubLoading(false);
                    setAllSubsExists(false);
                }
            })
        }
    }, [userPromo])

    useEffect(() => {
        if (!adminDetails && !userPromo) {
            history.replace("/login")
        }
    }, [adminDetails, userPromo])
    return (
        <>
            <div className="jumbotron text-center">
                <h3 className="text-primary">My Sales</h3>
                {viewDetails ? <button onClick={() => setViewDetails(false)} className="btn btn-danger">Hide Details</button> : <button onClick={() => setViewDetails(true)} className="btn btn-primary">View your referral sales</button>}
            </div>
            {viewDetails ? <div>
                {allSubsExists ?
                    <section className="py-4">
                        <div className="container">
                            <Table responsive bordered hover variant="primary">
                                <thead>
                                    <tr className="">
                                        <th>#</th>
                                        <th>PromoCode</th>
                                        <th>Purchased by</th>
                                        <th>Course</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allSubscriptions && allSubscriptions.map((item, index) => <SubscriptionCard data={item} serial={index} />)}
                                    {allSubscriptions && allSubscriptions.length == 0 && <h3 className="text-center text-danger" >No sale yet.</h3> }
                                </tbody>
                            </Table>

                        </div>
                    </section>
                    : <h3>No Sales yet!</h3>}
                {allSubLoading && <SectionSpinner />}
            </div> : <div style={{ height: "250px" }}>&nbsp;</div>}

        </>
    );
};

export default PageMySales;

const SubscriptionCard = ({ data, serial }) => {
    return (
        <>
            <tr className="" >
                <td>{serial + 1} </td>
                {/* <td>10/12/3200</td> */}
                <td>{data.promoCode} </td>
                <td>{data.userId}</td>
                <td>{data.course.name}</td>
                <td>{customDateFormat(data.course.startAt)}</td>
                <td> <span className="text-danger font-weight-bold"> {data.amount}</span></td>
            </tr>
        </>
    )
}

export const customDateFormat = (utcDate) => {
    if (utcDate) {
        let today = new Date(utcDate.seconds * 1000);
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        // today = mm + '-' + dd + '-' + yyyy;
        // console.log(today);
        // today = mm + '/' + dd + '/' + yyyy;
        // console.log(today);
        // today = dd + '-' + mm + '-' + yyyy;
        // console.log(today);
        today = dd + '/' + mm + '/' + yyyy;
        return today
    }else{
        return "Not yet"
    }
}