import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SpanBold from "../../Components/SpanBold/SpanBold";
import { allPaymentsRef, commissionWithdrawRef, promoCollectionRef, userPaymentsRef } from "../../firebase/fireApp";
import firebase from "firebase";
import { customDateFormat } from "../MySales/PageMySales";


const ComponentPointsCash = ({ data }) => {
    const [modalShow, setModalShow] = useState(false);
    // const [procession, setProcessing] = useState(false);
    const newExpiryDate = new Date(new Date().getTime()+(180*24*60*60*1000));
    
    const confirmTransaction =(pCode)=>{
        const paymentData ={
            amount: pCode.currentCommission,
            data: new Date(),
            paymentMethod: "AffiliateToWallet",
            salesPersonId: "",
            transactionId: "AffiliateToWallet",
            userId: pCode.userId
        }
        const withdrawData ={
            date: new Date(),
            withdrawAmount: pCode.currentCommission,
            promoCode: pCode.id,
            receiver: pCode.userId,
            withdrawBy: pCode.userId,
            note: "AffiliateToWallet"
        }
        
        promoCollectionRef.doc(pCode.id).update({
            currentCommission: 0,
            withdrawCommission: firebase.firestore.FieldValue.increment(pCode.currentCommission)
        }).then(()=>{
            allPaymentsRef.add(paymentData).then(()=>{
                userPaymentsRef.doc(pCode.userId).get().then((docSnapshot)=>{
                    if(docSnapshot.exists){
                        userPaymentsRef.doc(pCode.userId).update({
                            amount: firebase.firestore.FieldValue.increment(pCode.currentCommission),
                            expiryDate: newExpiryDate
                        })
                    }else{
                        userPaymentsRef.doc(pCode.userId).set({
                            amount: firebase.firestore.FieldValue.increment(pCode.currentCommission),
                            expiryDate: newExpiryDate
                        })
                    }
                })
            })
        }).then(()=>{
            commissionWithdrawRef.add(withdrawData).then()
        })
        
        
    }

    return (
        <>
            <div className="col-md-6 mb-4">
                <div className="card bg-primary text-left text-white p-4 p-md-5 py-5">
                    <SpanBold title="Total affiliate sales" text={data.totalSubscriptionAmount ? data.totalSubscriptionAmount : 0} />
                    <SpanBold title="Total points earned" text={data.totalCommission ? data.totalCommission : 0} />
                    <SpanBold title="Current balance" text={data.currentCommission ? data.currentCommission : 0} />
                    <SpanBold title="Withdrawn" text={data.withdrawCommission ? data.withdrawCommission : 0} />
                    <SpanBold title="Last updated" text={customDateFormat(data.affiliateUpdate)}/>
                </div>
            </div>
            <div className="col-md-6 mb-4">
                <div className="card  p-4 p-md-5 py-5 ">
                    <div className="m-0 m-md-5 text-center">
                        <p>Your current account balance is<i> <span className="font-weight-bold" >BDT {data.currentCommission}</span>. </i></p>
                        {data.currentCommission >= 1 ? <p> <i className="font-weight-bold">BDT {data.currentCommission}</i> is ready to reload your app wallet! 😄 </p> :
                        <p>You cannot reload app wallet until your balance reach <i className="font-weight-bold">BDT 1 </i> 😢 </p>
                        }
                        {data.currentCommission >= 1 ? 
                        <button onClick={() => setModalShow(true)} className="btn btn-danger">Reload Now😄</button>    :
                        <button className="btn btn-danger" disabled>Wait 😢</button>
                    }
                    </div>
                </div>
            </div>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Reload app wallet
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Amount: <b> BDT {data.currentCommission}</b> </h4>
                    <p><i> <span className="font-weight-bold" >BDT {data.currentCommission}</span> is ready to be reloaded to your app wallet! </i></p>
                    <button onClick={()=>confirmTransaction(data)} className="btn btn-danger">Confirm Transaction</button>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=> setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ComponentPointsCash;