import { AuthContextProvider } from "./hooks/useAuth";
import { PromoContextProvider } from "./hooks/usePromo";
import Main from "./Pages/Main";

function App() {
  return (
    <>
      <AuthContextProvider>
        <PromoContextProvider>
          <Main />
        </PromoContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
