import { Link } from 'react-router-dom';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa"

const FooterBottom = () => {
    const location = "122/6 Monipuri Para, Tejgaon, Dhaka";
    const email = "info@prottoy.com.bd"
    const telephone = "+880 1762-693597"
    return (
        <footer
            style={{ borderTop: "1px solid #9AD1CB" }}
            className="bg-primary text-center text-white py-4"
        >
            <address className="mb-1" style={{ verticalAlign: "center" }} > <span className="icon-wrapper"><FaMapMarkerAlt /></span>  {location} | <FaEnvelope /> <a href={"mailto:" + email}>info@prottoy.com.bd</a> | <FaPhoneAlt /> <a href={"tel:" + telephone}>{telephone}</a>  </address>
            {/* <p className="mb-1" >
                <Link to="/">About us</Link> | <Link to="/terms-and-conditions">Terms & Conditions</Link> | <Link to="/">Refund Policy</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
            </p> */}

            <p className="mb-0">
                &copy; 2020 | prottoy.com.bd
        </p>{" "}
        </footer>
    );
};

export default FooterBottom;