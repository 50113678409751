import React from "react";
import { Spinner } from "react-bootstrap";
import "./PageSpinner.scss";

const PageSpinner = () => {
    return (
        <div className="full-page d-flex align-items-center justify-content-center">
            <Spinner animation="grow" variant="primary" />
            {/* <p className="text-primary">Loading...</p> */}
        </div>
    );
};

export default PageSpinner;
