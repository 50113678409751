import React, { useContext, useCallback, useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "./LoginPage.scss";
import { withRouter, Redirect, useHistory, useLocation } from "react-router";
import { AuthContext, useAuth } from "../../hooks/useAuth";
import fireApp from "../../firebase/fireApp";
import firebase from "firebase/app";
import "firebase/auth"
import GoogleIcon from "./google.png"
import { Link } from "react-router-dom";
import { usePromo } from "../../hooks/usePromo";


const LoginPage = () => {
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    const { setUserPromo, userPromo } = usePromo()
    const { adminDetails, setAdminDetails } = useAuth();
    const handleGoogleSignIn = () => {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function (result) {
            const { displayName, email, uid, photoURL } = result.user;
            const signedInUser = { name: displayName, email, userId: uid, photoURL }
            storeAuthToken(signedInUser);
        }).catch(function (error) {
            const errorMessage = error.message;
            alert(errorMessage);
        });
    }
    useEffect(() => {
        if (adminDetails && userPromo) {
            history.replace("/")
        }else{
            history.replace("/login")
        }
    }, [adminDetails, userPromo])
    const storeAuthToken = (signedInUser) => {
        sessionStorage.setItem("user", JSON.stringify(signedInUser));
        history.replace("/")
        // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
        //     .then(function (idToken) {
        //         sessionStorage.setItem('token', idToken);
        //         history.replace(from);
        //     }).catch(function (error) {
        //         // Handle error
        //     });
    }
    const signOut = () => {
        firebase.auth().signOut().then(res => {
            sessionStorage.removeItem("user");
            setAdminDetails(null);
            setUserPromo(null);
            history.replace("/login")
        }).catch(err => {
            alert(err);
        })
    }
    // const onSubmit = useCallback(
    //     async (data) => {
    //         const { email, password } = data;
    //         try {
    //             await fireApp
    //                 .auth()
    //                 .signInWithEmailAndPassword(email, password);
    //             storeAuthToken()
    //             // history.replace(from);
    //         } catch (error) {
    //             alert(error);
    //         }
    //     },
    //     [history]
    // );
    // const { currentUser } = useContext(AuthContext);
    // if (
    //     (currentUser && currentUser.email === "admin@prottoy.com.bd") ||
    //     (currentUser && currentUser.email === "support@prottoy.com.bd")
    // ) {
    //     return <Redirect to="/" />;
    // } else {
    //     fireApp.auth().signOut();
    // }

    return (
        <>
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-6 my-4 offset-md-3  text-center">
                        <a href="/">
                            <p></p>
                            {/* <img src={LogoBrand} className="logo-brand mb-5" alt="brand" /> */}
                        </a>
                        <div className="p-5 login-container">
                            <Link to="/"><h2 className="text-center">Go to your profile</h2> </Link>
                            <h3 className="text-center mb-3 mt-4">
                                Login Here
                            </h3>
                            <button onClick={handleGoogleSignIn} className="btn custom-auth-btn btn-block py-3 mb-3 mt-3">
                                <img src={GoogleIcon} alt="" />
                                Continue with Google
                            </button>
                            <p className="text-center mb-5">
                                Don't have an account?{" "}
                                <a href="/login">Create an account</a>
                            </p>
                            <button onClick={signOut} className="btn btn-danger">Sign out</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default withRouter(LoginPage);
