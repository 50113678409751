import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "./NavbarComponent.scss"
import firebase from "firebase/app";
import { useAuth } from "../../hooks/useAuth";
import { usePromo } from "../../hooks/usePromo";

const NavbarComponent = () => {
    const shadow = true;
    const history = useHistory();
    const {setUserPromo} = usePromo()
    const {adminDetails, setAdminDetails} = useAuth();
    const signOut = () => {
        firebase.auth().signOut().then(res => {
            sessionStorage.removeItem("user")
            setAdminDetails(null);
            setUserPromo(null);
            history.replace("/login")
        }).catch(err => {
            alert(err);
        })
    }
    return (
        <>
            <Navbar collapseOnSelect sticky="top" expand="lg" variant="dark" className={shadow ? "bg-primary" : "bg-sm-danger"}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <h4 className="text-white mb-0">Prottoy</h4>
                            {/* <img src={ImgLogo} class="site-logo" alt="" /> */}
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    {/* <Navbar.Collapse id="responsive-navbar-nav text-center"> */}
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav style={{ color: "#FFFFFF" }} className="ml-auto text-center">
                            <Nav.Item>
                                <Nav.Link eventKey="1" as={Link} to="/" className="ml-4">My Profile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2" as={Link} to="/my-referrals" className="ml-4">My Referrals</Nav.Link>
                            </Nav.Item>
                            {adminDetails ? <button onClick={signOut} className="ml-4 btn btn-danger">Sign out</button>:
                            <Nav.Item>
                            <Nav.Link eventKey="3" as={Link} to="/login" className="ml-4">Login</Nav.Link>
                        </Nav.Item> }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default NavbarComponent;