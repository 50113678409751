import React from 'react';
import FooterBottom from './FooterBottom';
import { LogoGreen, ImgLogo, ImgGooglePlay } from "../../images";

import {
    FaFacebookF,
    FaLinkedinIn,
    FaInstagram,
    FaYoutube
} from 'react-icons/fa'
import "./FooterComponent.scss"
import { appLink, fbLink, instaLink, linkedInLink, youTubeLink } from "./links";


const FooterComponent = () => {
    return (
        <>
            <div className="bg-primary pt-5 footer-top">

                <div className="container py-5  ">
                    <div className="row text-white">
                        <div className="col-md-4 text-center text-md-left">
                            <a target="-blank" href="https://prottoy.com.bd">
                                <img
                                    className="site-logo"
                                    style={{ marginBottom: "20px" }}
                                    src={ImgLogo}
                                    alt=""
                                />
                            </a>
                            <p>
                                Millions of people of all ages and from around
                                the world are improving their lives with us
            </p>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 text-center text-md-left">
                            <h5 className="mt-2 mb-2">Useful Links</h5>
                            <a target="_blank" href="https://prottoy.com.bd/brains-behind-prottoy" className="d-block footer-links text-white">Brains Behind Prottoy</a>
                            <a target="_blank" href="https://prottoy.com.bd/what-we-do" className="d-block footer-links text-white">What We Do</a>
                            <a target="_blank" href="https://prottoy.com.bd/privacy-policy" className="d-block footer-links text-white">Privacy Policy</a>
                            <a target="_blank" href="https://prottoy.com.bd/terms-and-conditions" className="d-block footer-links text-white">Terms & Conditions</a>
                        </div>
                        {/* <div class="col-md-2 text-center text-md-left">
            <h5 className="mt-2">Terms</h5>
            <ul>
                <li>Daily Online Exam</li>
                <li>Weekly Exam</li>
                <li>Combined Result</li>
                <li>Challenges</li>
            </ul>
        </div> */}
                        <div className="col-md-2 text-center text-md-left">
                            <div>
                                <p className="mb-0">Get the mobile app</p>
                                <a href={appLink} target="_blank" rel="noopener noreferrer">
                                    <img src={ImgGooglePlay} alt="play-store" className="download-button" />
                                </a>
                                <p className="mb-0">Follow us</p>
                                <div>
                                    <a className="social-icon" target="_blank" href={fbLink}><FaFacebookF /></a>
                                    <a className="social-icon" target="_blank" href={linkedInLink}><FaLinkedinIn /></a>
                                    <a className="social-icon" target="_blank" href={instaLink}><FaInstagram /></a>
                                    <a className="social-icon" target="_blank" href={youTubeLink}><FaYoutube /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </>
    );
};

export default FooterComponent;