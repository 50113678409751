import React, { useState, useContext, useEffect, createContext } from "react";
import { promoCollectionRef, subscriptionsCollectionRef } from "../firebase/fireApp"
import { useAuth } from "./useAuth";
import firebase from "firebase";

export const PromoContext = createContext();
export const PromoContextProvider = ({ children }) => {
    const promoManger = PromoManager();
    return (
        <PromoContext.Provider value={promoManger}>
            {children}
        </PromoContext.Provider>
    )
}

export const usePromo = () => useContext(PromoContext);

const PromoManager = () => {
    const { adminDetails } = useAuth();
    const [userPromo, setUserPromo] = useState(null);
    const [loadingPromo, setLoadingPromo] = useState(true);
    const [promoExists, setPromoExists] = useState(true);

    // subscriptions
    const [allSubscriptions, setAllSubscriptions] = useState(null);
    const [allSubLoading, setAllSubLoading] = useState(true);
    const [allSubsExists, setAllSubsExists] = useState(true);

    // un-checked subs
    const [unCheckedSubs, setUnCheckedSubs] = useState(null);
    const [uncheckedAmount, setUncheckedAmount] = useState(null)



    useEffect(() => {
        if (adminDetails) {
            const fetchPromo = async () => {
                promoCollectionRef.where("userId", '==', adminDetails.userId).onSnapshot((querySnapshot) => {
                    let promoList = [];
                    querySnapshot.forEach((doc) => {
                        promoList.push({ id: doc.id, ...doc.data() })
                    });
                    if (promoList[0]) {
                        setUserPromo(promoList[0]);
                        setLoadingPromo(false)
                    } else {
                        setPromoExists(false);
                        setLoadingPromo(false)
                    }
                })

            }
            fetchPromo()
        }
    }, [adminDetails])

    // useEffect(() => {
    //     if (userPromo) {
    //         subscriptionsCollectionRef.where("promoCode", "==", "20-CA-MHSS-03").orderBy("course.startAt", "desc").onSnapshot((querySnapshot) => {
    //             let fetchedSubs = [];
    //             querySnapshot.forEach((doc) => {
    //                 fetchedSubs.push({ id: doc.id, ...doc.data() })
    //             })
    //             if (fetchedSubs) {
    //                 setAllSubscriptions(fetchedSubs);
    //                 setAllSubLoading(false);
    //             } else {
    //                 setAllSubLoading(false);
    //                 setAllSubsExists(false);
    //             }
    //         })
    //     }
    // }, [userPromo]);

    // un checked
    useEffect(() => {
        if (userPromo) {
            if (userPromo.affiliateUpdate) {
                subscriptionsCollectionRef.where("promoCode", "==", userPromo.id).where("course.startAt", ">", new Date(userPromo.affiliateUpdate.seconds * 1000)).orderBy("course.startAt", "desc").onSnapshot((querySnapshot) => {
                    let fetchedSubs = [];
                    let unPaid = 0;
                    querySnapshot.forEach((doc) => {
                        fetchedSubs.push({ id: doc.id, ...doc.data() });
                        unPaid = unPaid + doc.data().amount;
                    })
                    if (unPaid) {
                        setUncheckedAmount(unPaid);
                    }
                    if (fetchedSubs) {
                        setUnCheckedSubs(fetchedSubs)
                    }
                })
            }else if(userPromo.createdAt){
                subscriptionsCollectionRef.where("promoCode", "==", userPromo.id).where("course.startAt", ">", new Date(userPromo.createdAt.seconds * 1000)).orderBy("course.startAt", "desc").onSnapshot((querySnapshot) => {
                    let fetchedSubs = [];
                    let unPaid = 0;
                    querySnapshot.forEach((doc) => {
                        fetchedSubs.push({ id: doc.id, ...doc.data() });
                        unPaid = unPaid + doc.data().amount;
                    })
                    if (unPaid) {
                        setUncheckedAmount(unPaid);
                    }
                    if (fetchedSubs) {
                        setUnCheckedSubs(fetchedSubs)
                    }
                })
            }
        }
    }, [userPromo]);

    useEffect(() => {
        if (uncheckedAmount && userPromo) {
            const commission = uncheckedAmount * userPromo.userCommission / 100;
            promoCollectionRef.doc(userPromo.id).update({
                totalCommission: firebase.firestore.FieldValue.increment(commission),
                currentCommission: firebase.firestore.FieldValue.increment(commission),
                totalSubscriptionAmount: firebase.firestore.FieldValue.increment(uncheckedAmount),
                affiliateUpdate: firebase.firestore.FieldValue.serverTimestamp()
            })
        }
    }, [uncheckedAmount])



    return {
        userPromo,
        setUserPromo,
        loadingPromo,
        promoExists,
        setAllSubscriptions,
        setAllSubLoading,
        setAllSubsExists,
        allSubscriptions,
        allSubLoading,
        allSubsExists,
        unCheckedSubs,
        uncheckedAmount

    }
}


                // const promoQuery = await promoCollectionRef.where("userId", '==', adminDetails.userId).get();
                // if(promoQuery.docs){
                //     const promoData = promoQuery.docs[0];
                //     console.log(promoQuery);
                //     setUserPromo(
                //         {id: promoData.id, ...promoData.data()}
                //     )
                //     setLoadingPromo(false);
                // }else{
                //     setPromoExists(false);
                //     setLoadingPromo(false)
                // }