import WelcomePage from "./WelcomePage/WelcomePage"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "./LoginPage/LoginPage";
import PageProfileDetails from "./ProfileDetails/PageProfileDetails";
import NavbarComponent from "../Components/NavbarComponent/NavbarComponent";
import { PrivateRoute } from "../hooks/useAuth";
import FooterComponent from "../Components/Footer/FooterComponent";
import PageMySales from "./MySales/PageMySales";

const Main = () => {
    return (
        <>
            <Router>
                <NavbarComponent/>
                <Switch>
                    
                    <Route exact path="/login"><LoginPage/> </Route>
                    <PrivateRoute exact path="/">
                        <PageProfileDetails/>
                    </PrivateRoute>
                    <PrivateRoute exact path="/my-referrals">
                        <PageMySales/>
                    </PrivateRoute>
                    {/* <Route exact path="/">
                        <WelcomePage/>
                    </Route> */}
                </Switch>
                <FooterComponent/>
            </Router>
        </>
    );
};

export default Main;