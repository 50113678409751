// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBBYG5hOkmcSoQUdNmWnMVYzyVPf-u48Rs",
    authDomain: "prottoy-16bf0.firebaseapp.com",
    databaseURL: "https://prottoy-16bf0.firebaseio.com",
    projectId: "prottoy-16bf0",
    storageBucket: "prottoy-16bf0.appspot.com",
    messagingSenderId: "104681094193",
    appId: "1:104681094193:web:98f9342fe5a773e1f52910",
    measurementId: "G-VDXMZVRNHJ"
};

export default firebaseConfig;