import React, { useState, useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import PageSpinner from "../Components/Spinner/PageSpinner";
import fireApp, { userInfoCollectionRef } from "../firebase/fireApp";



export const AuthContext = React.createContext();
export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);
    const [adminDetails, setAdminDetails]  = useState(null);

    useEffect(() => {
        fireApp.auth().onAuthStateChanged((usr) => {
            if (usr) {
                userInfoCollectionRef.doc(usr.uid).get()
                .then((doc)=>{
                    const uDetails = {userId: usr.uid, ...doc.data()};
                    setAdminDetails({...uDetails})
                })
                const { displayName, email, photoURL } = usr;
                const { uid } = usr.providerData[0];
                const signedInUser = { name: displayName, email, photo: photoURL, uid };
                
                setCurrentUser(signedInUser);
            }
            setPending(false);

        });
        

    }, []);
    if (pending) {
        return <><PageSpinner /></>;
    }
    return (
        <AuthContext.Provider value={{ currentUser, adminDetails, setAdminDetails }}>
            {children}
        </AuthContext.Provider>
    );
};


export const useAuth =()=> useContext(AuthContext);

export const PrivateRoute = ({ children, ...rest }) => {
    const { currentUser } = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (currentUser   ) ?  
                    (children) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location },
                            }}
                        />
                    )
            }
        />
    );
};

// export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
//     const { currentUser } = useContext(AuthContext);
//     return (
//         <Route
//             {...rest}
//             render={(routeProps) =>
//                 (currentUser &&
//                     currentUser.email === "admin@prottoy.com.bd") ||
//                     (currentUser && currentUser.email === "support@prottoy.com.bd") ? (
//                         <RouteComponent {...routeProps} />
//                     ) : (
//                         <Redirect
//                             to={{
//                                 pathname: "/login",
//                                 state: { from: routeProps.location },
//                             }}
//                         />
//                     )
//             }
//         />
//     );
// };