import SpanBold from "../../Components/SpanBold/SpanBold"

const ComponentPromoDetails = ({ data }) => {
    return (
        <>
            <div className="card bg-primary text-left text-white p-4 p-md-5 py-5">
                <h3>{data.id} </h3>
                <SpanBold title="Your commission" text={`${data.userCommission}%`} />
                <SpanBold title="User Discount" text={`${data.discountRate}%`} />
                <p className={" mb-2"} >uID : <span className="font-weight-bold text-small">{data.userId} </span> </p>                
            </div>
        </>
    );
};

export default ComponentPromoDetails;