import { useEffect } from "react";
import { useHistory } from "react-router";
import SectionSpinner from "../../Components/Spinner/SectionSpinner";
import { useAuth } from "../../hooks/useAuth";
import { usePromo } from "../../hooks/usePromo";
import ComponentPointsCash from "./ComponentPointsCash";
import ComponentPromoDetails from "./ComponentPromoDetails";
import ComponentUserCard from "./ComponentUserCard";
import "./PageProfileDetails.scss"

const PageProfileDetails = () => {
    const history = useHistory()
    const { adminDetails } = useAuth();
    const { userPromo, promoExists } = usePromo()

    useEffect(() => {
        if (!adminDetails && !userPromo) {
            history.replace("/login")
        }
    }, [adminDetails, userPromo])

    return (
        <>
            <section className="py-5 bg-light text-center">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 mb-4 ">
                            {adminDetails ? <ComponentUserCard data={adminDetails} /> : <SectionSpinner />}
                        </div>
                        <div className="col-md-6 mb-4">
                            {!promoExists && <h4 className="text-danger">Sorry! You don't have a promo Code.</h4>}
                            {promoExists && userPromo && <ComponentPromoDetails data={userPromo} />}
                        </div>
                    </div>
                    <div className="row d-flex align-items-center">
                        {promoExists && userPromo && <ComponentPointsCash data={userPromo} />}
                    </div>
                </div>
                {/* <button onClick={logBasic} className="btn btn-primary">User</button> */}
            </section>
        </>
    );
};

export default PageProfileDetails;